* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
}

.navbar-seection {
	width: 100%;
	position: sticky;
	top: 0px;
	background-color: #d7eaff !important;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar-brand {
	width: 200px;
	height: 105px;
}

.navbar-brand img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* -=-=-==-=-- */
.templte-section {
	width: 100%;
	height: auto;
	background: #e9f3fe;
}

/* -=-=-=--=-=- */
.highlight-content h1 {
	font-size: 80px;
	font-weight: bolder;
	color: #1C3078;
}

.highlight-content h4 {
	font-weight: bolder;
}

.highlight-content p {
	margin-bottom: 10px;
	color: black;
}

.body-content h2 {
	font-weight: bolder;
}

/* -=-=-=-=-=-=-==- */
.footer-section {
	width: 100%;
	height: auto;
	background-color: #1f2158!important;
	display: flex;
	align-items: center;
	padding: 20px 0px;
}
.footer{
	text-align: center;
	padding-bottom: 20px;
}
.footer-left p a {
	color: white;
	font-size: 30px;
}
.footer-right p {
	margin-bottom: 0px;
	font-size: 18px;
	color: white;
}

.pac-container {
	background-color: #FFF;
	z-index: 2001;
	position: fixed;
	display: inline-block;
	float: left;
}

.modal {
	z-index: 2000;
}

.modal-backdrop {
	z-index: 1000;
}

@media only screen and (max-width: 992px) {
	.navbar-brand {
		width: 60px;
		height: 120px;
	}
}