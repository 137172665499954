body {
    background-color: #f6f6f6 !important;
    color: #333333 !important;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Roboto', sans-serif;
}

.btn-icon:focus {
    box-shadow: none;
    outline: none;
}

.btn:focus {
    box-shadow: none;
}

.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
    background-color: #fff0bf !important;
    color: #BABDBF !important;
    border-color: #1c3078 !important;
}

.nav-link-gdc-selected {
    font-weight: bold !important;
}

div.font-montserrat {
    font-family: "Montserrat", sans-serif
}

div.font-roboto {
    font-family: "Robot", sans-serif
}

div.font-opensans {
    font-family: "Open Sans", sans-serif;
    font-size: 14px
}

/* div.rtl{ */
div.rtl {
    direction: rtl !important;
    text-align: right;
}

.sticky-top {
    width: calc(100% - 280px);
    position: fixed;
}

.marginTop {
    margin-top: 7.2% !important;
}




.header_top.dark {
    background-color: #2cbaec !important;
}

.sidebar_dark #left-sidebar {
    background-color: #f0f0f0 !important;
}

.sidebar_dark .sidebar .metismenu .active>a {
    color: #2cbaec !important;
    border-color: #2cbaec !important;
}

.brand-name {
    color: #2cbaec !important;
}

.metismenu a:hover {
    color: #1c3078;
    border-color: #1c3078;
}

.card-body .clearfix button {
    background-color: #1c3078;
    border-color: #1c3078;
}

#left-sidebar {
    border-right: 1px solid white;
}

.ribbon .ribbon-box.orange {
    background-color: #2cbaec !important;
}

.ribbon .ribbon-box.orange::before {
    border-color: #2cbaec !important;
    border-right-color: transparent !important;
}

.fa-eye {
    color: #1c3078 !important;
    font-size: 20px !important;
}

.fa-edit {
    color: #1c3078;
    font-size: 18px !important;

}

.notification .fa-envelope {
    color: #1c3078 !important;
    font-size: 25px !important;
}

.notification .fa {
    font-size: 25px;
    color: #1c3078;
}

.fa-trash-o {
    color: #1c3078;
    font-size: 20px !important;
}

.fa-file-text-o {
    color: #1c3078 !important;
    font-size: 17px !important;
}

.metismenu a {
    color: #1c3078;
    font-size: 15px !important;
}

#page_top {
    background-color: #aed582 !important;
}

.page-header {
    border-bottom: none;
}

/* .page-header .right .notification .dropdown a:hover {
    color: white !important;
} */
.badge-primary {
    background-color: red !important;
}

.page-title {
    color: #1c3078 !important;
}

.metismenu .g_heading {
    color: #1c3078 !important;
}

.metismenu ul a {
    color: #1c3078 !important;
}

.metismenu a i {
    color: #2cbaec !important;
}

.rounded_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1.5px solid #2cbaec;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
    margin-right: 10px;
    position: relative;
    color: #2cbaec;
}

.rounded_icon i,
.rounded_icon .rounded_icon_r {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #2cbaec;
}

.rounded_icon .rounded_icon_r {
    font-size: 25px;
}

.btn-round,
.btn-primary {
    background-color: #2cbaec !important;
    color: white !important;
    float: right;
    height: 45px !important;
    width: 115px !important;
    border-radius: 14px !important;
    font-size: 18px;
    font-weight: 500;
}

.btn-secondary {
    color: white !important;
    float: right;
    height: 45px !important;
    width: 115px !important;
    font-size: 18px;
    font-weight: 500;
}

.btn-round,
.btn-primary,
.btn-secondary:focus {
    box-shadow: none;
}

.form-control::placeholder {
    font-size: 15px !important;
}

.form-control {
    height: 38px;
    border: 1px solid #868686;
    font-size: 18px !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgb(165 208 255) !important;
    border-color: #a5d0ff !important;
}

.page .section-white,
.page .section-body {
    background-color: #f6f6f6 !important;

    /* .page .section-white, .page .section-body{
    background-color: #f7b1a1;
 */
}

.card-header .card-title {
    font-weight: 500;
}

.feeds_widget li:hover {
    background-color: #1c3078;
    color: white;
}

.feeds_widget li:hover .title a {
    color: white;
}

.feeds_widget li:hover .text-muted {
    color: white !important;
}

/* .dropdown-menu .dropdown-item:focus {
    background-color: #1c3078 !important;
} */

/* .dropdown-menu:hover .dropdown-item{
    color: white;
} */
/* .dropdown-menu-arrow .dropdown-item:hover i{
    color: red !important;
} */
/* .dropdown-menu-right .dropdown-item:hover{
    color: white;
} */
.dropdown-menu .dropdown-item.readall:hover {
    color: #555;
    font-weight: 500;
    padding: 0px !important;
}

.feeds_widget li:hover i {
    color: white;
}

.userlist_view {
    display: flex;
    justify-content: center;
}

.ag-cell div {
    /* display: flex; */
    justify-content: center;
}

.ag-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* =========================swal======================= */

.custom-top-search {
    background-color: #2cbaec !important;
    border-radius: 10px 0px 0px 10px !important;
}

.custom-top-search .fa-search:before {
    color: white !important;
}

.custom-input {
    width: 60% !important;
}

.custom-footer {
    font-size: 18px;
    color: #1c3078;
    text-align: center;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    background-color: white !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: none !important;
}

thead {
    height: 52px !important;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
}

.table thead th {
    color: #1c3078 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.table.table-hover tbody tr:hover {
    background: none !important;
}

.table td,
.table th {
    font-size: 16px !important;
}

.custom-sidebar-logo {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    padding: 7px;
}

.table>:not(:first-child) {
    border-top: 1.1px solid #1c3078 !important;
}

.page-header .right .nav-pills .dropdown {
    background: #1c3078 !important;
}

.ag-center-cols-clipper a {
    color: #1c3078 !important;
}

.ag-center-cols-clipper .btn-icon i {
    color: #1c3078 !important;
}

#Add,
.btn-primary {
    height: 45px !important;
    /* width: 115px !important; */
    border-radius: 14px !important;
}

.ag-header-viewport {
    background-color: white !important;
}

.ag-header-container {
    font-size: 16px !important;
}

.ag-center-cols-container {
    font-size: 13px;
}

.ag-header-cell-text {
    color: #1c3078 !important;
}

.fa-file-text-o:before {
    color: #1c3078 !important;
}

.fa-edit:before {
    color: #1c3078;
}

::selection {
    color: white !important;
    background-color: #1c3078 !important;
}

.auth .auth_left {
    background-color: #2cbaec !important;
}

.auth .btn-primary {
    display: inline-block;
    width: 100% !important;
}

.auth {
    background-color: #f0f0f0 !important;
}

.form-label .small {
    color: #1c3078 !important;
}

.auth .header-brand {
    color: #1c3078 !important;
}

.nav-tabs .nav-link.active {
    border-color: #1c3078 !important;
    color: #1c3078 !important;
}

#header_top .brand-logo {
    color: #1c3078 !important;
}

.card-title i {
    font-size: 20px !important;
}

.ag-paging-panel {
    font-size: 17px !important;
    color: #1c3078 !important;
}

.ag-icon {
    font-size: 17px !important;
    color: #1c3078 !important;
}

/* .tag-success,
.tag-green {
    background: none;
    border: 2px solid #cedd7a;
    color: #cedd7a;
    font-weight: 800;
    min-width: 68px;
    justify-content: center;
    margin-top: 5px;
} */
.tag-green {
    color: #13b686;
    border: 1.5px solid #13b686;
    background-color: white;
    font-weight: 500;
    width: 92px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: auto;
    margin-top: 25px;
}

.tag-danger {
    color: #d45050;
    border: 1.5px solid #d45050;
    background-color: white;
    font-weight: 500;
    width: 92px !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: auto;
    margin-top: 25px !important;
}

.tag-info {
    color: #000;
    border: 1.5px solid #acb5ba;
    background-color: #fff;
    font-weight: 500;
    width: 115 !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: auto;
    margin-top: 25px;
    border-radius: 30px;
}

.tag-info i {
    color: #2cbaec;
    margin-top: 9px;
}

.tag-decline i {
    color: #f62d51;
    margin-top: 9px;
}

.tag-approval i {
    color: #aed582;
    margin-top: 9px;
}

.tag-decline {
    color: #000;
    border: 1.5px solid #acb5ba;
    background-color: #fff;
    font-weight: 500;
    width: 115 !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: auto;
    margin-top: 25px;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.tag-approval {
    color: #000;
    border: 1.5px solid #acb5ba;
    background-color: #fff;
    font-weight: 500;
    width: 115 !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: auto;
    margin-top: 25px;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.user_status {
    padding: 3px 10px;
}

.user_status .tag-green {
    margin-top: 0px;
}

#Departments-list .ag-header-cell-label {
    display: flex;
    justify-content: center;
}

#Departments-list .ag-theme-balham .ag-cell,
.ag-theme-balham .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    display: flex;
    justify-content: center;
}

.tag-danger {
    background: none;
    border: 2px solid #e8769f;
    color: #e8769f;
    font-weight: 800;
}

.hotel_sec .tag-danger {
    background: none;
    border: 2px solid #e8769f;
    color: #e8769f;
    font-weight: 800;
    margin-top: 5px;
}

.project_inactive {
    margin-top: 25px !important;
}

/* .user_sec .tag-danger {
    margin-top: 5px !important;
} */



.page-header .right .nav-item .dropdown-toggle {
    padding: 10px 25px;
}

.page-header .right .nav-item .dropdown-toggle i {
    margin: 0px 10px !important;
}

/* .dropdown-menu .dropdown-item:hover {
    background-color: #1c3078 !important;
    color: white;
} */

/* .dropdown-menu .dropdown-item:hover{
    color: white !important;
} */
.avatar {
    background-color: white !important;
}

.auth_right .carousel-indicators {
    bottom: -50px;
}

.auth_right .carousel-indicators button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0px 5px;
    margin-top: 30px;
}

.auth_right .carousel-control-prev {
    display: none;
}

.list-unstyled {
    /* height: 300px; */
    overflow: auto;
}

::-webkit-scrollbar {
    display: block !important;
}

.list-unstyled ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.carousel-inner {
    height: 518px;
}

.carousel-item {
    width: 100%;
    height: 400px;
}

.carousel-item img {
    width: 100%;
    height: 500px;
}

.carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* -------------------------------- */
.css-1s2u09g-control {
    border-color: #868686 !important;
}

select {
    border-color: #868686 !important;
}

.name {
    height: 4px !important;
}

.h_name {
    color: #2cbaec !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-title {
    color: #1c3078;
}

.form-label {
    color: #1c3078;
}

.card-body+.card-body {
    border-top: 0px !important;
}

.dashboard {
    color: #1c3078;
}

.dashboardText {
    color: #1c3078;
    text-align: center;
}

/* ============================================= */

input,
textarea,
select {
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
    border-radius: 14px !important;
}

.css-1s2u09g-control {
    height: auto !important;
    border-radius: 14px !important;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%) !important;
}

.form-select {
    height: auto !important;
    border-radius: 14px !important;
}

.custom-input {
    border-radius: 0px 10px 10px 0px !important;
}

.card .card-options .dropdown-toggle {
    height: 45px !important;
    width: 115px !important;
    border-radius: 10px !important;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
    color: #1c3078 !important;
    padding: 0px 16px;
    border: 1px solid #333333;
}

.dashbord_icons {
    width: 35px;
    height: 35px;
    border: 1px solid #6e6e6e;
    border-radius: 50%;
    padding: 6px;
    margin: 0 auto;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
    position: relative;
}

.dashbord_icons i {
    font-size: 20px;
}

::-webkit-file-upload-button {
    background-color: #d7eaff !important;
    color: #1c3078 !important;
}

.ag-header-row-column {
    background-color: #e9f3fe !important;
}

.ag-header-row-floating-filter {
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
}

.ag-row {
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
    display: flex !important;
    align-items: center !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
    border: none !important;
    /* padding: 20px 0px !important; */
    line-height: 85px !important;
    vertical-align: middle;
}

.right-allign-menu {
    float: right;
}

.site_pass i {
    position: absolute;
    right: 20px;
    top: 35px;
}

.card-options a:not(.btn):hover {
    color: #fff;
}

.modal-header {
    border-radius: 20px 20px 0 0;
}

.modal-content,
.modal-header {
    background: linear-gradient(0deg, #e9f3fe 0, #fff 35%);
}

.payroll_toggle {
    font-size: 22px !important;
    margin-left: -19px !important;

}

.form-check-input:checked {
    background-color: #1c3078 !important;
    border-color: #1c3078 !important;
}

.todo_increment {
    font-size: 35px;
    margin-top: 7px;
}

.todo_increment span {
    color: #1c3078;
    cursor: pointer;
    margin: 0 10px;
}

/* -------------------------- */


.breadcrump p {
    padding: 20px 20px 0px 40px;
    margin-bottom: 0px;
    display: flex;
}

.breadcrump h6 {
    margin-bottom: 0px;
    padding-top: 4px;
    font-size: 13px;
}

.breadcrump span {
    padding: 0px 5px;
}

/* ---------- save button -------- */
.commor_save {
    background-color: #2cbaec !important;
    color: white !important;
    float: right;
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
}


.approval_save {
    background-color: #fcfcfc !important;
    color: #000 !important;
    float: right;
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
    border: 2px solid #2cbaec;
}

.approval_save:hover {
    background-color: #2cbaec !important;
    color: #fff !important;
}

.reject_save {
    background-color: #fcfcfc !important;
    color: #000 !important;
    float: right;
    /* width: 140px; */
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px !important;
    border: 2px solid #ff0000;
}

.reject_save:hover {
    background-color: #ff0000 !important;
    color: #fff !important;
}

.commor_save .fa-trash-o {
    color: #fff;
}

/* ------- view category ------ */
.category_access ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.category_access li {
    display: inline-block;
    padding: 0px 20px;
}

.category_access .tag {
    padding: 0px 25px;
    font-size: 15px;
}

.user_permission {
    color: #1c3078 !important;
    font-weight: bold;
    font-size: 15px;
    width: fit-content !important;
    position: absolute;
    top: 0px;
    left: 0px;
}

.table thead th {
    color: #1c3078;
    font-weight: 800 !important;
    font-size: 15px !important;
    vertical-align: middle;
}

.table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.edit_user_table {
    height: 600px;
    overflow: auto;
    border: 1px solid #dee2e6;
    background-color: #fff !important;
}

.edit_user_table_Custom {
    height: auto;
    overflow: auto;
    border: 1px solid #dee2e6;
}

.edit_user_table thead {
    position: sticky;
    top: -3px;
    z-index: 1;
    border-top: 2px solid #dee2e6;
}

.edit_user_table {
    background-color: #e9f3fe !important;
}

/* ------ checkbox------ */

.custom-checkbox .custom-control-label:before {
    border: 1px solid #868686 !important;
    width: 22px !important;
    height: 22px !important;
    margin-top: 0px;
    margin-left: 7px;
}

.custom-checkbox .custom-control-label:after {
    top: 3px !important;
    left: -16px !important;
    width: 20px !important;
    height: 19px !important;
}

.custom-checkbox {
    top: 17px;
}


/* --------------- common loader start-- ag-grid --------- */
.table-responsive {
    position: relative;
}

.common_loader_ag_grid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.loader_img_style_common_ag_grid {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
}

.spinner_load_common_ag_grid {
    height: 80px;
    width: 80px;
}

/* --------------- meti menu  ------------- */
.metismenu .fa-angle-down {
    float: right;
    margin-top: 3px;
}

/* =========modal================= */
.modal-header {
    border-radius: 20px 20px 0 0 !important;
}

/* ---------------------------- */
.site_pass i {
    position: absolute;
    right: 10px;
    top: 10px;
}

.site_pass,
.site_pass .form-control {
    position: relative;
}

.mandatory {
    display: flex;
    justify-content: space-between;
}

.form-check-input[type=checkbox] {
    border-radius: 4px !important;
}

.password_check {
    position: absolute;
    top: -5px;
    left: 120px;
}

.mandatory-label {
    margin-top: 12px;
}

.img_remove {
    right: 15px;
    position: absolute;
    top: 5px;
    font-size: 22px;
    color: #2cbaec;
    background-color: #fff;
    cursor: pointer;
}

.photos_wid {
    display: flex;
    flex-wrap: wrap;
}

.payslip_content .payslip_heading {
    font-size: 20px;
    text-decoration: underline;
    text-align: center;
    word-spacing: 10px;
    font-weight: bold;
}

.payslip_content .payslip_Id {
    font-weight: bolder;
    text-align: right;
}

.payslip_content .payslip_date {
    text-align: right;
    font-weight: 500;
}

.payslip_company_intro .company_logo {
    width: 200px;
    height: 200px;
    /* margin-left: 20px; */
}

.payslip_company_intro .company_logo img {
    width: 100%;
    height: 100%;
}

.compnay_address {
    padding-top: 20px;
}

.compnay_address p {
    margin-bottom: 5px;
    font-weight: 500;
}

.employee_profile p {
    margin-bottom: 5px;
    font-weight: 500;
}

.employee_profile .employee_name {
    font-weight: 600;
    font-size: 20px;
}

.emp_role {
    font-weight: 500;
}

.payslip_tables h4 {
    font-weight: bolder;
    font-size: 20px;
    padding-bottom: 20px;
}

.payslip_tables ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.payslip_tables ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid #333333;
    border-bottom: none;
}

.payslip_tables ul li:last-child {
    border-bottom: 1px solid #333333;
}

/* .payslip_tables .row .col-lg-6{
    padding: 0px !important;
} */
/* .payslip_tables ul li strong {
    font-weight: bolder
} */

.payslip_tables ul li p {
    font-weight: 500;
    margin-bottom: 0px;
}

.total_amount strong {
    font-weight: bolder;
}

.net_salary {
    padding-top: 20px;
}

.net_salary strong {
    font-weight: bolder;
}

.net_salary p {
    font-weight: 500;
}

.payslip_tables ul li :nth-child(even) {
    border: none;
}

.total_amount p {
    font-weight: bolder !important;
}

.download_options {
    padding: 0px 35px;
}

.download_options .pagination {
    justify-content: end;
}

.download_options .pagination .page-item .page-link {
    color: black;
    font-weight: bold;
}

@media print {
    @page {
        /* size: A4; */
        /* DIN A4 standard, Europe  */
        /* margin-top: 10px !important;
        margin-bottom: 35px !important; */
        /* margin: 2cm */
    }


    body *:not(#print_page):not(#print_page *) {
        visibility: hidden;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        /* size:8.5in 11in; */
        /* margin: 25mm 25mm 25mm 25mm !important;
        
        size: auto; */

        /* margin: 10mm 0 10mm 0; */
        /* margin: 2.5cm 0; */
        /* size: A4; */
    }

    #print_page {
        visibility: visible !important;
    }

    #print_page {
        position: absolute !important;
        top: 0px !important;
        left: -300px !important;
        width: 140% !important;
        overflow: auto !important;
        /* margin: 5px; */
    }

    .print_design {
        margin-left: 50px;
        /* height: auto; */
    }

    .print_subtotal .row {
        width: 100%;
        float: left;
    }

    .print_subtotal .col-lg-10 {
        width: 80%;
        float: left;
    }

    .print_subtotal .col-lg-2 {
        width: 20%;
        float: left;
    }

    .invoice_generate {
        padding: 10px !important;
        position: relative !important;
    }

    .Invoice_ID {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        position: absolute;
        top: 120px;
        font-size: 30px;
        font-weight: 500;
        color: #ccc;
        left: 10px !important;
        height: auto;
    }



    .invoice_generate .row {
        width: 100%;
        float: left;
    }

    .invoice_generate .col-lg-8 {
        width: 60%;
        float: left;
    }

    .invoice_generate .col-lg-4 {
        width: 40%;
        float: left;
    }

    .invoice_generate .col-lg-6 {
        width: 50%;
        float: left;
    }



    .invoice_generate .col-lg-5 {
        width: 40%;
        float: left;
    }

    .invoice_generate .col-lg-2 {
        width: 20%;
        float: left;
    }

    .invoice_generate .col-lg-2 {
        width: 20%;
        float: left;
    }

    .invoice_generate .col-lg-3 {
        width: 20%;
        float: left;
    }

    .emppayslip .payslip_tables .row {
        width: 100%;
        float: left;
    }

    .emppayslip .payslip_tables .col-lg-6 {
        width: 50%;
        float: left;
    }

    .emppayslip .payslip_tables .col-lg-6 {
        width: 50%;
        float: left;
    }

    .emppayslip .net_salary {
        width: 100%;
        margin-top: 20px;
        clear: both;
    }

    .emppayslip .media_print_deductions {
        margin-top: 0px;
    }
}

/* =================================== */
.filter_section {
    padding: 0px 20px;
}

.search_btn_salary button {
    background-color: #2cbaec !important;
    color: white !important;
    height: 45px !important;
    width: 115px !important;
    border-radius: 14px !important;
    font-size: 18px;
    font-weight: 500;
    border: none;
}

.search_btn_salary {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.payslip_btn {
    color: white;
    border: 1.5px solid #13b686;
    background-color: #13b686;
    font-weight: 500;
    width: auto !important;
    height: 36px !important;
    display: flex;
    justify-content: center;
    font-size: 13px;
    margin: auto;
    margin-top: 25px;
}

.payslip_btn:hover {
    color: white;
}

.add_emp_salary .col-lg-6 h4 {
    font-weight: bolder;
    font-size: 17px;
    color: #2cbaec;
}

.employee_info h3 {
    font-size: 22px;
    font-weight: bolder;
    color: #2cbaec;
}

.employee_info .nav-item .nav-link {
    background-color: #2cbaec !important;
    float: right;
    height: 45px !important;
    width: auto !important;
    border-radius: 14px !important;
    font-size: 18px;
    font-weight: 500;
    margin: 0px 1px;
}

.employee_info .nav-item .nav-link {
    color: white !important;
    font-size: 15px;
}

.employee_info .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #1c3078 !important;
    border: 1px solid #2cbaec;
    background: none !important;
    font-weight: bolder;
}

.employee_info #pills-tabContent h4 {
    font-weight: bolder;
    font-size: 19px;
    text-align: center;
    padding: 20px 0px;
}

.employee_info #pills-tabContent h5 {
    font-weight: bolder;
    font-size: 18px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: #2cbaec;
    text-decoration-style: double;

}

.employess_prev_next_btn .nav-item {
    justify-content: end;
    margin-bottom: 10px;

}

.employess_prev_next_btn .nav-item .nav-link {
    width: 100px !important;
    justify-content: center;
    font-size: 18px;
    font-weight: bolder;
}

.todo_increment {
    font-size: 35px;
    margin-top: 20px;
}

.todo_increment span {
    color: #1c3078;
    cursor: pointer;
    margin: 0 10px;
}

/* ======================= */

.compose_btn {
    background-color: #2cbaec !important;
    color: white !important;
    float: right;
    height: 45px !important;
    border-radius: 14px !important;
    font-size: 18px;
    font-weight: 500;
}

/* ======================= normal table ================ */
/* .table,
tr {
    border: 1px solid #ccc;
} */

.table>:not(:first-child) {
    border-top: 1.1px solid #1c3078 !important;
}

.table.table-striped tbody tr:nth-of-type(odd) {
    background: transparent;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: none !important;
}

thead {
    background: linear-gradient(0deg, #e9f3fe 0, #fff 35%);
    height: 52px !important;
}

tbody,
td,
tfoot,
tr {
    background: linear-gradient(0deg, #e9f3fe 0, #fff 35%);
    border-bottom: 1.1px solid #ccc;
    height: 66px !important;
}

/* =============================================== */
.email_sec .custom-checkbox {
    top: -2px;
}

.underline_double_color {
    text-decoration: underline;
    text-decoration-color: #2cbaec;
    text-decoration-style: double;
}

.email_list .description {
    white-space: nowrap;
    width: 840px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;

}

.email_list .tomail {
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
}

.email_list .table_row:hover .maildate {
    display: none;
    width: 125px;
}

.email_list .table_row .maildeleteicon {
    display: none;
}

.email_list .table_row:hover .maildeleteicon {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
}

.vie_email_templete .content {
    margin: 50px 0px;
}

.vie_email_templete .top_title {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
}


.vie_email_templete .top_title .icons_replay i {
    font-size: 20px;
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
}

.vie_email_templete .top_title .icons_replay span {
    font-weight: 500;
    color: #000;
}

.vie_email_templete .bottom_title {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid #ccc;
}

/* ============= task_board_sec========= */
.task_board_sec .lt_img_create_link .avatar-list-stacked .avatar {
    margin-right: -0.2em !important;
}

/* .task_board_sec .lt_img_create_link .text_img {
    display: flex;
} */

.task_board_sec .lt_img_create_link .text_img .text {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 6px;
}


.task_board_sec .lt_img_create_link .top_button {
    float: right;
}

.task_board_sec .lt_img_create_link .top_button button {
    border: 1px solid #ccc;
    font-weight: 600;
}


.task_board_sec .lt_img_create_link .text_img .fe-plus {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2cbaec;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    cursor: pointer;
}

.task_board_sec .outer-wrapper {
    max-width: 100vw;
    overflow-x: scroll;
    position: relative;
    scrollbar-color: #2cbaec;
    scrollbar-width: thin;
    -ms-overflow-style: none;
}

.task_board_sec .pseduo-track {
    background-color: #2cbaec;
    height: 2px;
    width: 100%;
    position: relative;
    top: -3px;
    z-index: -10;
}

@media (any-hover: none) {
    .task_board_sec .pseduo-track {
        display: none;
    }
}

.task_board_sec .outer-wrapper::-webkit-scrollbar {
    height: 10px;
}

.task_board_sec .outer-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.task_board_sec .outer-wrapper::-webkit-scrollbar-thumb {
    height: 10px;
    background-color: #2cbaec;
}

.task_board_sec .outer-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #2cbaec;
}

.task_board_sec .outer-wrapper::-webkit-scrollbar:vertical {
    display: none;
}

.task_board_sec .inner-wrapper {
    display: flex;
    padding-bottom: 10px;
}

.task_board_sec .pseudo-item {
    /* height: 300px; */
    width: 300px;
    margin-right: 40px;
    flex-shrink: 0;
    background-color: rgb(240, 236, 236);
}


.ticket_tracker {
    box-shadow: 0 0 5px #bfbbbb;
    padding: 10px;
    margin-top: 40px;
}

.ticket_tracker .top_box_title {
    border-radius: 0px;

}

.ticket_tracker .top_box_title1 .card-header h3 {
    color: #fff;
}

.ticket_tracker .top_box_title1 .card-header .fe-more-vertical {
    font-size: 20px;
    color: #000;
    font-weight: 500;
}

.ticket {
    cursor: pointer;
}

.ticket_tracker .top_box_title1 .card-header .item-action a:hover i,
.ticket .item-action a:hover i {
    color: #fff !important;
}

.ticket_tracker .top_box_title1 .card-header .item-action a:hover .fa-edit::before,
.ticket .item-action a:hover .fa-edit::before {
    color: #fff !important;
}

.fa-edit {
    color: #2cbaec !important;
}

.ticket_tracker .top_box_title1 .card-header .item-action .dropdown-item:hover {
    background-color: #2cbaec;
    color: #fff;
}

.ticket_tracker .top_box_title1 .card-header .item-action .dropdown-menu,
.ticket .item-action .dropdown-menu {
    min-width: 6rem;
    left: 100px !important;
}

.ticket_tracker .top_box_title1 .card-header .item-action .dropdown-menu .dropdown-item,
.ticket .item-action .dropdown-menu .dropdown-item {
    padding: 5px;
    width: 90px;
}

.ticket {
    padding: 10px;
    box-shadow: 0 0 5px #bfbbbb;
    background-color: #fff;
    margin-bottom: 10px;
}

.ticket .ticket-header,
.ticket .ticket-header h5 {
    color: #000;
    font-size: 13px;
    width: 95%;
}

.ticket .time_userlogo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.ticket .time_userlogo .img_size {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.ticket .time_userlogo .img_size img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.ticket_tracker .ticket_out_side {
    padding: 10px;
    background-color: rgb(240, 236, 236);
}

.ticket .card-options a:not(.btn):hover {
    color: #fff;
}

.ticket .three_dot {
    width: 5%;
    /* position: relative; */
}

/* .ticket .item-action {
    position: absolute;
    bottom: 0px;
} */
.ticket .task_icon {
    display: flex;
    margin-bottom: 10px;

}

.ticket .task_icon .dropdown {}

.ticket .task_icon .task_text {
    margin-bottom: 0px;
    width: 95%;
    font-size: 15px;
}

.ticket .task_icon .icon {
    margin-top: 0px;
    width: 5%;
}

.ticket .task_icon .dropdown-menu {
    min-width: 8rem;
    left: 100px !important;
}

.ticket .task_icon .dropdown-menu .dropdown-item {
    padding: 5px;
}

.task_board_model .task_board_radio {
    display: flex;
    justify-content: space-between;
}

.task_board_model .task_board_radio input[type="radio"] {
    display: none;
}

.task_board_model .task_board_radio label {
    padding: 1em;
    display: inline-block;
    cursor: pointer;
    width: 50px;
    height: 55px;
}

.task_board_model .task_board_radio label i {
    color: #fff;
}

.task_board_model .task_board_radio .blank-label {
    display: none;
}

.task_board_model .task_board_radio input[type="radio"]:checked+label {
    background: grey;
    color: #fff;
}

.ticket_out_side .add_task_link {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}

/* ==================project_category =========== */
.project_category {
    box-shadow: 0 0 5px #333 !important;
    cursor: pointer;
}

.extra_em .dropdown-menu-right {
    left: 150px !important;
}

.clear_filter {
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    border-radius: 0px;
    font-weight: 500;
}

.swith_toggle_salary {
    position: relative;
    top: -15px;
}

.replay_box {
    padding: 20px;
    box-shadow: 0 0 5px #b7afaf !important;
}

.replay_box .title {
    margin-bottom: 20px;

}

.replay_box .title span {
    font-weight: 500;
}

.replay_box textarea {
    width: 100%;
    border: none;

}

.replay_box textarea:focus {
    outline: none;
    border: none;
}

/* =================leaves_count_col=========== */
.leaves_count_col .card-body {
    box-shadow: 0 0 5px #b7afaf !important;
}

.leave_up_icon {
    display: flex;
    align-items: center;
}

.swal_image {
    max-width: 100%;
    margin: 15px auto 10px !important;
    position: absolute;
    left: 40px;
    top: 65px;
}

.swal_pop {
    background: linear-gradient(to top, #e9f3fe 10%, #fff 35%) !important;
    border-radius: 14px !important;
    width: 560px !important;
    height: 219px !important;
    border-radius: 14px !important;
    border: 1px solid #6e6e6e !important;
    padding: 10px;
}

.swal_title {
    color: #1c3078 !important;
    font-size: 25px !important;
    padding: 5rem 1rem 0rem 4rem !important;
}

.modal_btn .ok_btn,
.swal2-confirm {
    background-color: #1c3078 !important;
    border-radius: 7px !important;
    color: #fff !important;
    font-size: 18px;
    font-weight: 500;
    height: 40px !important;
    width: 90px !important;
    padding: 0px !important;
}

.card-title {
    font-size: 18px;
    color: #1c3078;
    font-weight: 500 !important;
}

.card-title i {
    font-size: 23px;
    color: #2cbaec !important;
}

.modal_btn .cancel_btn {
    /* background: linear-gradient(0deg,#e9f3fe 0,#fff 35%);
    border: 1px solid #6e6e6e;
    border-radius: 7px!important;
    color: #1c3078;
    font-size: 18px;
    font-weight: 500;
    height: 40px!important;
    margin: 0 10px;
    padding: 3px;
    width: 90px!important; */
}

.swal2-styled.swal2-cancel {
    background: linear-gradient(0deg, #e9f3fe 0, #fff 35%) !important;
    border: 1px solid #6e6e6e !important;
    border-radius: 7px !important;
    color: #1c3078 !important;
    font-size: 18px;
    font-weight: 500;
    height: 40px !important;
    margin: 0 10px;
    padding: 3px;
    width: 90px !important;
}

/* ================================= */
.topsocial_rest_btn {
    background-color: #1c3078 !important;
    border-radius: 10px !important;
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;
    height: 45px;
}

.emp_reset_btn {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.seen {
    background-color: #d8d1d1;
    color: #000;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    width: 50px;
    height: 13px;
    position: relative;
    top: 3px;
}

.payslip_highlight_bg {
    background-color: #acb5ba;
}

.payslip_highlight_bg p,
strong {
    font-weight: bolder !important;
}

.leave_approval .img_name {
    display: flex;
    margin-bottom: 20px;
}

.leave_approval .img_name .img_size {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

.leave_approval .img_name .img_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.leave_approval .img_name p {
    margin-bottom: 0px;
    font-weight: 500;
    margin-bottom: 5px;
}

.kyc_image_view {
    width: 100px;
    height: 100px;
    display: inline-flex;
    margin: 0px 10px;
}

.kyc_image_view img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.login_log {
    width: 70px;
    height: 60px;
    object-fit: contain;
    display: inline-block;
}

.login_log img {
    width: 100%;
    height: 100%;
}

.payrollgen_btn .btn-primary {
    width: auto !important;
}

input[type="text"]:disabled {
    background: #eee;
}

.leave_transec_date .rmdp-input {
    height: 40px !important;
    width: 531px !important;
}

.payslip_tables tbody,
td,
tfoot,
tr {
    height: 0px !important;
    padding: 0px;
}

.table td,
.table th {
    padding: 6px;
}

.invoice_address p {
    margin-bottom: 5px;
}

.invoice_address h4 {
    color: #E51B24;
    font-size: 30px;
}

.invoice_detail_billto_heading {
    font-weight: bolder;
    font-size: 17px;
    width: 400px;
    border-bottom: 2px solid #000;
}

.inv_det {
    float: right;
}

.detslin {
    display: flex;
    justify-content: space-between;
    clear: both;
    width: 400px;
    float: right;
}

.detslin p {
    margin-bottom: 0px;
}

.table_invoice .col_border_right {
    padding: 0px;
    border-right: 1px solid #000;
}

.table_invoice .desc_h6 {
    background-color: #E51B24 !important;
    color: white;
    padding: 10px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: bold;
}

.invoice_address a {
    color: #000;
}

.invoice_address a:hover {
    color: #000;
}

.tocompname {
    font-weight: bold;
}

.tocompany_logo {
    width: 80px;
    height: 80px;
    margin: 20px 0px;
}

.tocompany_logo img {
    width: 100%;
    height: 100%;
}

.table_invoice .desc_h5 {
    font-size: 20px;
    font-weight: bolder;
    border: 1px solid #000;
    margin-bottom: 0px;
    padding: 20px 0px;
    padding-left: 5px;
}

.table_invoice .desc_p {
    border: 1px solid #000;
    margin-bottom: 0px;
    padding: 3px;
    padding-left: 5px;
    font-size: 20px;

}

.table_invoice .row {
    border-bottom: 1px solid #000;
    position: relative;
}

.next_tab_div {
    text-align: center;
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 50%;
    margin-bottom: 0px;
    padding: 0px 10px;
    text-align: center;
}

.invoice_detail_billto_heading {
    /* background-color: #2cbaec; */
    color: #E51B24;
    padding: 10px 0;
}

.invoice_heading_tex {
    font-weight: bolder;
}

.phone_num_drop {
    position: absolute;
    z-index: 1;
    background: #fff;
    width: 98%;
    border: 1px solid #b4a9a9;
    border-radius: 10px;
}

.phone_num_drop ul {
    display: inline-block;
    padding: 10px;
    margin: 0px;
    width: 100%;
}

.phone_num_drop ul li {
    display: block;
    padding: 4px;
    list-style-type: none;
    cursor: pointer !important;
}

.phone_num_drop ul li:hover {
    display: inline-block;
    padding: 4px;
    background-color: #d7eaff;
    list-style-type: none;
    width: 100%;
    cursor: context-menu;
}

.dropdown_scroll_guest {
    width: 97%;
    background-color: #fff;
    overflow: auto;
    height: 175px;
}

.dropdown_scroll_guest::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    transition: 0.3s background;
    border: 2px solid #ccc;
}

.dropdown_scroll_guest::-webkit-scrollbar-thumb {
    background: #1c3078 !important;
}

.dropdown_scroll_guest:hover::-webkit-scrollbar-thumb {
    background: #1c3078 !important;
}

.dynaic_tex_invoice {
    font-weight: 500;
    letter-spacing: 1px;
}

.dynaic_tex_invoice_due {
    font-weight: 500;
    color: #E51B24;
    letter-spacing: 1px;
}

.invoice_dis .dis_Name,
.Disc_amount {
    padding: 20px 0px;
    text-align: center;
    margin-bottom: 0px !important;
}

.invoice_dis .dis_Name {
    font-weight: bolder;
}

.invoice_dis .row .col-lg-10 {
    border-right: 1px solid #000;
    border-left: 1px solid #000;

}

.invoice_dis .row .col-lg-2 {
    border-right: 1px solid #000;
}

.subtotal_invoice .col-lg-2 {
    border: 1px solid #000;
    padding: 0px;
}

.balance_name_invoice {
    color: #E51B24;
    font-weight: bolder;
    margin-bottom: 0px;
    text-align: center;
    padding: 20px 0px;
}

.current_amount_invoice {
    background-color: #F0C206;
    color: #000;
    font-weight: bolder;
    font-size: 20px;
}

.current_amount_invoice i {
    margin: 0px 10px;
}

.Invoice_ID {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    position: absolute;
    top: 120px;
    font-size: 30px;
    font-weight: 500;
    color: #ccc;
    left: 0px;
}

.Hello_Inoice_Span {
    writing-mode: vertical-rl;
    /* transform: rotate(180deg); */
    position: absolute;
    top: 120px;
    font-size: 30px;
    font-weight: 500;
    color: #ccc;
    left: 0px;
}

.Hello_Inoice_Span span {
    padding: 10px 0px !important;
    text-align: center !important;
}

.invoice_generate {
    position: relative;
    padding: 20px 60px;
}

.change_btn_invoice {
    width: auto !important;
}

#chnagepwd .close {
    font-size: 30px;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    /* top: 0;
    left: 0;
    right: 0; */
    bottom: 0;
    background-color: rgb(255, 255, 255, 0.5);
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 2;
}

#overlay-model {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255, 0.5);
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 2;
}

/* --------------- common loader start-- ag-grid --------- */
.table-responsive {
    position: relative;
}

.common_loader_ag_grid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.loader_img_style_common_ag_grid {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
}

.spinner_load_common_ag_grid {
    height: 80px;
    width: 80px;
}

/* --------------- common loader end ------------- */
/* --------------- common loader in view & edit common page ------------- */
.page {
    height: 100vh;
}

.common_loader {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.loader_img_style_common {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
}

.spinner_load_common {
    height: 80px;
    width: 80px;
}

/* ------------- common loader in view & edit common page end  ---------- */

/* -------------- model spinner start ------------- */
.model_loader_poss {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader_img_style_model {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    padding: 8px;
    position: absolute;
}

.spinner_load_model {
    z-index: 999;
    height: 80px;
    width: 80px;
}

/* -------------- model spinner end --------------- */

.loader_img_style_dsh {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 250px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

.spinner_load_dash {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 250px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

/* ------------pr nx ----------------- */
.spinner_load_prenx {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 90px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

.loader_img_style_prenx {
    position: absolute !important;
    top: 60px !important;
    left: 580px !important;
    margin-top: 90px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

/* ------------------------------ */

.loader_img_style_his {
    position: absolute !important;
    top: 0px !important;
    left: 580px !important;
    margin-top: 150px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

.spinner_load_his {
    position: absolute !important;
    top: 0px !important;
    left: 580px !important;
    margin-top: 150px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

.loader_img_style_soc {
    position: absolute !important;
    top: 200px !important;
    left: 605px !important;
    margin-top: 250px;
    height: 80px;
    width: 80px;
    z-index: 999;
    border-radius: 50%;
    padding: 8px;
}

.spinner_load_soc {
    position: absolute !important;
    top: 200px !important;
    left: 605px !important;
    margin-top: 250px;
    z-index: 999;
    height: 80px;
    width: 80px;
}

.invoice_pwd_btn .btn-primary {
    width: auto !important;
}

#generate_zip .close {
    font-size: 35px;
}

#generate_zip .modal-title {
    padding-top: 10px;
}

#print_page {
    width: 100%;
}

.metismenu {
    margin-bottom: 40px;
}

.invoice_detail_billto_heading {
    letter-spacing: 0.2px;
}

.desc_h5 {
    letter-spacing: 0.2px;

}

.dis_Name {
    letter-spacing: 0.2px;
}

.balance_name_invoice {
    letter-spacing: 0.2px;
}

.button_auto_front_desk {
    background-color: #2CBAEC !important;
    color: white !important;
    float: right;
    width: auto !important;
    font-size: 18px;
    font-weight: 500;
    height: 45px !important;
    border-radius: 14px !important;
}

.ticket_open,
.ticket_assigned,
.ticket_inprogress,
.ticket_inreview,
.ticket_reopened,
.ticket_closed,
.ticket_completed {
    align-items: center;
    display: inline-block;
    font-weight: 500 !important;
    width: 90px !important;
    height: 35px !important;
    font-size: 13px !important;
    border-radius: 6px !important;
    padding-top: 3px !important;
    text-align: center;
    color: #444;
}

.userView .col-lg-5 {
    border-right: 1px solid #6e6e6e;
  }
  
  .userView .calendarborder {
    border-right: 1px solid #6e6e6e;
  }
  
  .userView .user_hotel_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .user_hotel_logo {
    width: 150px;
    height: 150px;
    margin-top: 20px;
  }

  .user_hotel_logo {
    width: 100%;
    height: auto;
  }

  .User_part_left .category_name {
    padding: 5px 8px;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
    border: 1px solid #6e6e6e;
    border-radius: 8px;
    font-weight: bold;
    color: #1c3078;
  }

  
.userView .ribbon .ribbon-box.orange {
    position: absolute;
    left: -31px;
    top: -12px;
  }
  
  .userView .user_hotel_logo {
    width: 100px;
    height: 100px;
    margin: 30px auto;
  }
  
  .userView .user_hotel_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .User_part_right .hotel_name {
    text-align: center;
  }

  .User_part_right div {
    padding-bottom: 10px;
  }

  .User_part_right .hotel_name {
    font-weight: bold;
    font-size: 18px;
    color: #1c3078;
    margin-bottom: 0px;
    padding-top: 10px;
  }
  
  .User_part_right label {
    font-size: 18px;
    color: #1c3078;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .User_part_right span {
    /* font-size: 18px; */
    color: #444444;
    /* font-weight: 500; */
  }
  